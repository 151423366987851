import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import { Layout } from "../../components";
import Block from "../../components/cta.js";
import VizPage from "../../components/vizPage.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">

    <Layout mdxType="Layout">
      <VizPage heading="Affidavit of Non-Compliance" mdxType="VizPage">
        <iframe src="https://public.tableau.com/views/AffadavitofNon-Compliance/AffadavitofNoncompliance?:showVizHome=no&:embed=true" allowFullScreen="true" width="1015" height="835"></iframe>
        <br></br>
        <h3>{`About`}</h3>
        <br></br>
        <dt> <strong> Eviction Filing: </strong> represents a court action typically filed by a landlord or property manager to begin eviction proceedings.</dt>
        <dt> <strong> Affidavit of Non-Compliance: </strong>  a stipulation that states the defendant(s) did not comply with the terms of the agreement, usually a stipulated dismissal. </dt>
        <br></br>
        <p><strong parentName="p">{`Eviction Records`}</strong></p>
        <br></br>
        <p>{`Eviction data is pulled from the WCCA REST Interface — programmatic access to Wisconsin Circuit Court records. Small claims cases in Milwaukee County with a case type of small claims eviction are pulled down and stored on a weekly basis. CCAP provides no warranties as to the accuracy or timeliness of the information contained in the WCCA Data.`}</p>
      </VizPage>
    </Layout>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      